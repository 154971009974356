<template>
  <div>
    <v-slide-group class="carousel--wrapper">
      <v-slide-item
        v-for="(coverage, index) in coverages"
        :key="`coverage-${index}`"
        class="carousel--slide"
      >
        <a :href="coverage.url" target="_blank" class="carousel--link">
          <img
            :src="coverage.img"
            :alt="coverage.name"
            :style="{ padding: coverage.padding }"
            class="carousel--image img-responsive"
          />
        </a>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  computed: {},
  data: () => ({
    coverages: [
      {
        url: 'https://www.youtube.com/watch?v=qqJ1EWFSQKk',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/idx.png',
        name: 'IDX Channel',
        padding: '20px',
      },
      {
        url: 'https://dailysocial.id/post/belirumah-proptech-rebranding',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/daily_social.png',
        name: 'DailySocial.id',
        padding: '16px',
      },
      {
        url: 'https://industri.kontan.co.id/news/bidik-pasar-milenial-platform-beliruma-sediakan-transaksi-properti-lewat-online',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/kontan.png',
        name: 'Kontan.co.id',
        padding: '12px',
      },
      {
        url: 'https://momsmoney.kontan.co.id/news/berganti-nama-belirumah-perkenalkan-layanan-untuk-tingkatkan-penjualan-agen-properti-1',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/moms_money.png',
        name: 'MomsMoney.id',
        padding: '12px',
      },
      {
        url: 'https://ekonomi.bisnis.com/read/20211004/47/1450563/real-estate-teknologi-luncurkan-beliruma-mudahkan-beli-rumah',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/bisnis.png',
        name: 'Bisnis.com',
        padding: '16px',
      },
      {
        url: 'https://www.tribunnews.com/bisnis/2021/10/06/bantu-miliki-rumah-melalui-lewat-online-beliruma-kawal-konsumen-dapatkan-rumah-idaman',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/tribunnews.svg',
        name: 'Tribunnews',
        padding: '16px',
      },
      {
        url: 'https://bisnisindonesia.id/article/proptech-belirumah-sebut-hunian-rp500-jutaan-diminati-konsumen',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/bisnis_indonesia.png',
        name: 'BisnisIndonesia.id',
        padding: '12px',
      },
      {
        url: 'https://radarjakarta.com/berita-17247-beliruma-resmi-menjadi-belirumah.html',
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/coverages/radar_jakarta.png',
        name: 'Radar Jakarta',
        padding: '12px',
      },
    ],
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.carousel--slide {
  @media #{$phones} {
    margin: 4px 8px;
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
  }
  .carousel--image {
    width: 180px;
    height: 180px;
    background-color: $color-white;
    border-radius: 24px;
    box-shadow: $box-shadow;
    object-fit: contain;
    @media #{$phones} {
      width: 140px;
      height: 140px;
    }
  }
}
</style>
